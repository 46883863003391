export default class GroupSelectionResponseOptionStyling {
  public static createEmpty(): GroupSelectionResponseOptionStyling {
    return new GroupSelectionResponseOptionStyling();
  }
  constructor(
    public OptionTitle = false,
    public OptionAssignedGroup = false,
    public OptionSeatQuantity = false,
  ) {}
}
