
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class GroupSelectionLandingPage extends Vue {
  private groupSelectonIcon = require('@/assets/Images/group-placeholder-icon.svg');
  // When no item available create new link for create new group selection item
  private CreateNewGroupSelectionItem() {
    this.$emit('create-new-item');
  }
}
