
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import GroupSelectionOptionViewModel from '@/entities/Participants/GroupSelectionOptionViewModel';
import { VueDraggableNext } from 'vue-draggable-next';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import CreateGroup from '@/components/Common/CreateGroup.vue';
import { ParticipantGroups } from '@/entities/ParticipantGroups';
import GroupSelectionResponseOptionStyling from '@/entities/Participants/GroupSelectionResponseOptionStyling';
import { Root } from '@/main';
@Component({
  components: {
    draggable: VueDraggableNext,
    vSelect,
    CreateGroup,
  },
})
export default class GroupSelectionResponseOptions extends Vue {
  @Prop() responseOptions!: GroupSelectionOptionViewModel[];
  @Prop() groups!: ParticipantGroups[];
  @Prop() isDisabled!: boolean;
  private localResponseOptions: GroupSelectionOptionViewModel[] = []; // local group selection response option
  private optionAddCounter = 0; // Used to assign value to sort order
  private minimumOptions = 2; // minimum response options
  private isMobileView = false;
  private dropdown = [false, false]; // Used to handle all the response options in a question such that z-index-2 class can be applied for currently selected dropdown
  private groupSelectionResponseOptionStyling: GroupSelectionResponseOptionStyling[] = []; // Used to give border either red or green on basis of their values
  private validateFields = false;
  private selectedGroupsIds: number[] = []; // Selected group Id's of a response options in a group selection question
  private areGroupsFiltered = false; // Used to check whether the groups are filtered such that not two response options can have same group
  private minSeatsQuantity = 1;
  private maxSeatsQuantity = 100000;
  private maxGroupSelectionOptionLength = 100;
  private dragDropIcon = require('@/assets/Images/drag-and-drop-icon.svg');
  private deleteIcon = require('@/assets/Images/Delete-icon.svg');
  private mounted() {
    this.localResponseOptions = Array.from(this.responseOptions);
    // Adding resoponse options when response options length is 0
    if(this.responseOptions.length === 0) {
      this.AddNewGroupSelectionResponseOption();
      this.AddNewGroupSelectionResponseOption();
    }
    // Checking whether it is mobile view or not
    if (window.innerWidth <= 1024) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    // Call API to get group selections data
    this.GetGroupsForSelection(ParticipantGroups.createEmpty());
    // Reset group selection data to it's default
    Root.on('reset-group-selection',()=> {
      this.localResponseOptions = [];
      this.optionAddCounter = 0;
      this.AddNewGroupSelectionResponseOption();
      this.AddNewGroupSelectionResponseOption();
    });
    // Validate fields when save button is clicked
    Root.on('save-group-selection-question-clicked',()=> {
      this.validateFields = true;
    });
  }
  // Setting entered seat quantity to particular option
  private SetMaxLimitSeatQuantity(index: number) {
    if(!this.localResponseOptions[index].IsLimitSeat && this.localResponseOptions[index].LimitSeatQuantity === -1) {
      this.localResponseOptions[index].LimitSeatQuantity = 1;
    }
  }
  // Update hook
  private updated() {
    this.$emit('create-update-response-option-data',this.localResponseOptions);
  }
  private DraggingStart() {
    // code to perform any operation on drag start
  }
  // Draggable component on drag end event
  private DraggingEnd() {
    // code to perform any operation on drag end
    this.ChangeSortOrderOfResponseOptions();
  }
  // Used to change the sort order
  private ChangeSortOrderOfResponseOptions() {
    this.localResponseOptions.forEach((e) => {
      // change sort order of element
      e.SortOrder=this.localResponseOptions.indexOf(e)+1;
    });
  }
  // Validating seats quantity entered
  private ValidateMaxSeats(event: Event, index: number) {
    const input = event.target as HTMLInputElement;
    const value = parseInt(input.value, 10);
    this.localResponseOptions[index].LimitSeatQuantity = value;
  }
  // Emit when create new group button clicked
  private CreateNewGroup(index: number) {
    Root.emit('display-group-creation', {showPopup: true, selectedItemIndex: index});
  }
  // Setting group selected to particular option
  private SetSelectedGroupId(groupName: any, index: number) {
    if(groupName === null) {
      this.localResponseOptions[index].AssignedGroup.Name = '';
      this.localResponseOptions[index].AssignedGroup.Id = 0;
    } else {
      this.localResponseOptions[index].AssignedGroup.Id = groupName.Id;
    }
    this.GetSelectedGroupIds();
  }
  // Getting all groups id's that's been previously selected
  private GetSelectedGroupIds() {
    this.selectedGroupsIds = [];
    if(this.localResponseOptions !== null && this.localResponseOptions !== undefined) {
      this.localResponseOptions.forEach((item)=> {
        // Storing all groups assigned to response options
        if(item.AssignedGroup.Id !== 0) {
          this.selectedGroupsIds.push(item.AssignedGroup.Id);
        }
      });
    }
  }
  // Filtering group such that no response option can have same group
  private GetGroupsForSelection(selectedGroup: ParticipantGroups) {
    const filteredGroup: ParticipantGroups[] = [];
    this.groups.forEach((item)=> {
      // Adding all the groups to the filteredGroup which are not assigned
      if(!this.selectedGroupsIds.includes(item.Id)) {
        filteredGroup.push(item);
      }
    });
    // Adding the group to array of groups which is currently assigned to the particular clicked dropdown
    if(selectedGroup.Id !== 0) {
      filteredGroup.unshift(selectedGroup);
    }
    this.areGroupsFiltered = true;
    return filteredGroup;
  }
  // Used to add option
  private AddNewGroupSelectionResponseOption() {
    if (this.optionAddCounter === 0) {
      this.optionAddCounter = this.localResponseOptions.length;
    }
    const item = GroupSelectionOptionViewModel.createEmpty();
    item.SortOrder = this.optionAddCounter + 1;
    this.localResponseOptions.push(item);
    this.optionAddCounter++;
    this.dropdown.push(false);
  }
  // Used to remove option
  private RemoveGroupSelectionResponseOption(sortOrder: number) {
    const objIndex = this.localResponseOptions.findIndex(
      (obj: GroupSelectionOptionViewModel) => obj.SortOrder === sortOrder,
    );
    if (objIndex > -1) {
      this.localResponseOptions.splice(objIndex, 1);
      this.dropdown.pop();
    }
  }
  // Hide remove buttons when editing is disabled or only two options avalilable
  private HideRemoveButtons() {
    return this.isDisabled || this.localResponseOptions.length <= this.minimumOptions;
  }
  // Hide sort buttons when editing is disabled
  private HideSortButtons() {
    return this.isDisabled || this.isMobileView;
  }
  // This is used to remove the z-index added to the list items of options
  private ResetDropDown(val: boolean) {
    for(let i = 0 ; i< this.dropdown.length;i++) {
      this.dropdown[i] = val;
    }
  }
  // This is used to add z-index such that drop-down doesn't hide behind other item in list
  private DisplayDropDown(index: number) {
    this.ResetDropDown(false);
    this.dropdown[index] = true;
  }
  // Validating options and adding border color
  private ValidateOptionsData(val: boolean) {
    if(this.validateFields) {
      if(val) {
        return 'background-color:#FFF;border: solid #FF0000 2px;';
      } else {
        return '';
      }
    }
  }
  // Watch
  @Watch('responseOptions', {deep: true})
  // Use to assign value to localResponseOptions on any change in ResponseOptions
  private CheckChangeInResponseOptions(val: GroupSelectionOptionViewModel[]) {
    if(val.length !== 0) {
      this.localResponseOptions = val;
      this.optionAddCounter = this.localResponseOptions.length;
      this.GetSelectedGroupIds();
    }
  }
  // Used to validate the fields on value change
  @Watch('localResponseOptions', {deep: true})
  private CheckChangeInlocalResponseOptions(val: GroupSelectionOptionViewModel[]) {
    this.groupSelectionResponseOptionStyling = [];
    val.forEach((item)=> {
      const optionStyling: GroupSelectionResponseOptionStyling = GroupSelectionResponseOptionStyling.createEmpty();
      if(item.ResponseOptionTitle !== null && item.ResponseOptionTitle !== undefined && item.ResponseOptionTitle.trim() === '') {
        optionStyling.OptionTitle = true;
      }
      if(item.AssignedGroup.Name !== null && item.AssignedGroup.Name !== undefined && item.AssignedGroup.Name.trim() === '') {
        optionStyling.OptionAssignedGroup = true;
      }
      if((item.LimitSeatQuantity < this.minSeatsQuantity || item.LimitSeatQuantity > this.maxSeatsQuantity || isNaN(item.LimitSeatQuantity)) && item.IsLimitSeat) {
        optionStyling.OptionSeatQuantity = true;
      }
      this.groupSelectionResponseOptionStyling.push(optionStyling);
    });
  }
}
