export class ParticipantGroups {
  public static createEmpty(): ParticipantGroups {
    return new ParticipantGroups();
  }
  constructor(
    public Id: number = 0,
    public Name: string = '',
    public ParticipantCounts: number = 0,
  ) { }
}

