
import { WarningMessageBarViewModel } from '@/entities/WarningMessageBarViewModel';
import { Component, Vue, toNative, Prop, Watch } from 'vue-facing-decorator';

@Component({})
class WarningMessageBar extends Vue {
  @Prop() private informationViewModel!: WarningMessageBarViewModel;
  private localViewModel: WarningMessageBarViewModel =
    WarningMessageBarViewModel.createEmpty();
  private CrossIcon: string = require('@/assets/Images/cross-icon.svg');
  private InformationIcon = require('@/assets/Images/info-icon.svg');
  private readonly Information = 'Information!';

  private mounted() {
    this.localViewModel = this.informationViewModel;
    if (this.informationViewModel !== null && this.informationViewModel !== undefined && this.localViewModel.IconURL !== null && this.localViewModel.IconURL !== undefined) {
      this.InformationIcon = this.localViewModel.IconURL;
    }
  }
  private Close() {
    this.$emit('information-message-bar-close-operation');
  }
  @Watch('informationViewModel')
  private CheckChangeInInformationViewModel(val: WarningMessageBarViewModel) {
    this.localViewModel = val;
    if(this.localViewModel.IconURL !== null && this.localViewModel.IconURL !== undefined) {
      this.InformationIcon = this.localViewModel.IconURL;
    }
  }
}
export default toNative(WarningMessageBar);
