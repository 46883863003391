export class WarningMessageBarViewModel {
  public static createEmpty(): WarningMessageBarViewModel {
    return new WarningMessageBarViewModel();
  }
  constructor(
    public IconURL: any = '',
    public Message: string = '',
    public InformationType: string = '',
    public IsCloseIconVisible: boolean = false,
  ) { }
}
