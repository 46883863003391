import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, TransitionGroup as _TransitionGroup, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38792e40"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "margin-top-25 margin-bottom-0" }
const _hoisted_2 = { class: "color-red" }
const _hoisted_3 = {
  key: 0,
  class: "valign-parent50 cursor-move handle drag-drop-icon"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = ["onUpdate:modelValue", "disabled", "maxlength"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onUpdate:modelValue", "disabled"]
const _hoisted_9 = ["onUpdate:modelValue", "disabled", "onInput"]
const _hoisted_10 = ["value"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vSelect = _resolveComponent("vSelect")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", _hoisted_1, [
      _createTextVNode(_toDisplayString(_ctx.$t('ResponseOptions')), 1),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("Asteric")), 1)
    ]),
    _createVNode(_component_draggable, {
      list: _ctx.localResponseOptions,
      tag: "ul",
      class: "list-group",
      handle: ".handle",
      onStart: _cache[1] || (_cache[1] = ($event: any) => (_ctx.draggingStart($event, { passive: false }))),
      onEnd: _cache[2] || (_cache[2] = ($event: any) => (_ctx.draggingEnd($event, { passive: false })))
    }, {
      default: _withCtx(() => [
        _createVNode(_TransitionGroup, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localResponseOptions, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.SortOrder,
                class: _normalizeClass([[_ctx.dropdown[index] ? 'z-index-2': '', {'response-options-card border-radius-10': _ctx.isMobileView}], "margin-top-10"])
              }, [
                (_ctx.areGroupsFiltered)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: item.SortOrder,
                      class: "drag-item response-options"
                    }, [
                      (!_ctx.HideSortButtons())
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createElementVNode("img", { src: _ctx.dragDropIcon }, null, 8, _hoisted_4)
                          ]))
                        : _createCommentVNode("", true),
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": ($event: any) => ((item.ResponseOptionTitle) = $event),
                        disabled: _ctx.isDisabled,
                        type: "text",
                        class: _normalizeClass(["form-control", {'width100': _ctx.isMobileView}]),
                        style: _normalizeStyle(_ctx.ValidateOptionsData(_ctx.groupSelectionResponseOptionStyling[index].OptionTitle)),
                        maxlength: _ctx.maxGroupSelectionOptionLength
                      }, null, 14, _hoisted_5), [
                        [_vModelText, item.ResponseOptionTitle]
                      ]),
                      _createVNode(_component_vSelect, {
                        modelValue: item.AssignedGroup.Name,
                        "onUpdate:modelValue": [($event: any) => ((item.AssignedGroup.Name) = $event), ($event: any) => (_ctx.SetSelectedGroupId($event, index))],
                        options: _ctx.GetGroupsForSelection(item.AssignedGroup),
                        class: _normalizeClass([_ctx.isMobileView ? 'margin-top-10' : 'margin-left-10' ,{'width100': _ctx.isMobileView}]),
                        placeholder: _ctx.$t('Participants.GroupSelection.AssignGroup'),
                        disabled: _ctx.isDisabled,
                        reduce: AssignedGroup => AssignedGroup.Name,
                        style: _normalizeStyle(_ctx.ValidateOptionsData(_ctx.groupSelectionResponseOptionStyling[index].OptionAssignedGroup)),
                        label: "Name",
                        index: "Id",
                        onOpen: ($event: any) => (_ctx.DisplayDropDown(index)),
                        onDelecting: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ResetDropDown(true))),
                        "onOption:selected": ($event: any) => (_ctx.SetSelectedGroupId($event, index))
                      }, {
                        "list-footer": _withCtx(() => [
                          _createElementVNode("div", {
                            class: "create-new-group-btn",
                            onClick: ($event: any) => (_ctx.CreateNewGroup(index))
                          }, _toDisplayString(_ctx.$t('Participants.GroupSelection.CreateNewGroup')), 9, _hoisted_6)
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "onUpdate:modelValue", "options", "class", "placeholder", "disabled", "reduce", "style", "onOpen", "onOption:selected"]),
                      _createElementVNode("div", {
                        class: _normalizeClass(["limit-seats-div", [_ctx.isMobileView ? 'margin-top-10' : 'margin-left-10' ,{'width100': _ctx.isMobileView}]])
                      }, [
                        _createElementVNode("label", {
                          class: "no-margin limit-seats description",
                          onClick: ($event: any) => (_ctx.SetMaxLimitSeatQuantity(index))
                        }, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": ($event: any) => ((item.IsLimitSeat) = $event),
                            disabled: _ctx.isDisabled,
                            type: "checkbox",
                            class: "checkbox-alignment"
                          }, null, 8, _hoisted_8), [
                            [_vModelCheckbox, item.IsLimitSeat]
                          ]),
                          _createTextVNode(_toDisplayString(_ctx.$t('Participants.GroupSelection.LimitSeats')), 1)
                        ], 8, _hoisted_7),
                        (item.IsLimitSeat)
                          ? _withDirectives((_openBlock(), _createElementBlock("input", {
                              key: 0,
                              "onUpdate:modelValue": ($event: any) => ((item.LimitSeatQuantity) = $event),
                              disabled: _ctx.isDisabled,
                              style: _normalizeStyle(_ctx.ValidateOptionsData(_ctx.groupSelectionResponseOptionStyling[index].OptionSeatQuantity)),
                              type: "number",
                              class: "form-control margin-left-10 seats-quantity",
                              onInput: ($event: any) => (_ctx.ValidateMaxSeats($event, index))
                            }, null, 44, _hoisted_9)), [
                              [_vModelText, item.LimitSeatQuantity]
                            ])
                          : (_openBlock(), _createElementBlock("input", {
                              key: 1,
                              type: "text",
                              disabled: "",
                              class: "form-control margin-left-10 seats-quantity",
                              value: _ctx.$t('Unlimited')
                            }, null, 8, _hoisted_10)),
                        _createElementVNode("div", {
                          class: _normalizeClass({'margin-left-auto': _ctx.isMobileView})
                        }, [
                          _createElementVNode("button", {
                            class: _normalizeClass([{'visibility-hidden': _ctx.HideRemoveButtons()}, "btn btn-delete"]),
                            onClick: ($event: any) => (_ctx.RemoveGroupSelectionResponseOption(item.SortOrder))
                          }, [
                            _createElementVNode("img", { src: _ctx.deleteIcon }, null, 8, _hoisted_12)
                          ], 10, _hoisted_11)
                        ], 2)
                      ], 2)
                    ]))
                  : _createCommentVNode("", true)
              ], 2))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["list"]),
    (!_ctx.isDisabled)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "hyperLink padding-bottom-20 bold-text",
          href: "javascript:void(0)",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.AddNewGroupSelectionResponseOption()))
        }, _toDisplayString(_ctx.$t('Participants.GroupSelection.AddAnotherOption')), 1))
      : _createCommentVNode("", true)
  ]))
}