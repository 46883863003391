
import { Vue, Component } from 'vue-facing-decorator';
import GroupSelections from './GroupSelections/GroupSelections.vue';
@Component({
  components: {
    GroupSelections,
  },
})
export default class Groups extends Vue {

}
