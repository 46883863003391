import { ParticipantGroups } from '../ParticipantGroups';
export default class GroupSelectionOptionViewModel {
  public static createEmpty(): GroupSelectionOptionViewModel {
    return new GroupSelectionOptionViewModel();
  }
  constructor(
    public Id: number = 0,
    public GroupSelectionId: number = 0,
    public ResponseOptionTitle: string = '',
    public AssignedGroup: ParticipantGroups = ParticipantGroups.createEmpty(),
    public IsLimitSeat: boolean = false,
    public LimitSeatQuantity: number = -1,
    public SortOrder: number = 0,
    public IsDeleted: boolean = false,
  ) {}
}
