import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9a3e9270"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "landing-page" }
const _hoisted_2 = { class: "right-container-padding" }
const _hoisted_3 = { class: "main-heading-parent" }
const _hoisted_4 = { class: "primary-heading font-size-20" }
const _hoisted_5 = { class: "sub-heading margin-top-15" }
const _hoisted_6 = { class: "description" }
const _hoisted_7 = { class: "description margin-top-15" }
const _hoisted_8 = { class: "no-item-message-container" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "bold-text cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("Participants.GroupSelection.LandingPageHeading")), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("Participants.GroupSelection.LandingPageSubHeadingLine1")), 1),
        _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("Participants.GroupSelection.LandingPageSubHeadingLine2")), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("span", {
          class: "no-item-message",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.CreateNewGroupSelectionItem()))
        }, [
          _createElementVNode("img", {
            src: _ctx.groupSelectonIcon,
            class: "margin-right-20"
          }, null, 8, _hoisted_9),
          _createElementVNode("a", _hoisted_10, _toDisplayString(_ctx.$t("Participants.GroupSelection.CreateGroupSelectionQuestion")), 1)
        ])
      ])
    ])
  ]))
}