
import {Vue, Component} from 'vue-facing-decorator';
import Groups from '@/components/Participants/Groups/Groups.vue';
import { Root } from '@/main';
@Component({
  components: {
    Groups,
  },
})
export default class Participants extends Vue {
  private mounted() {
    Root.emit('set-active-component', 'participant');
  }
}
