
import { Component, Vue } from 'vue-facing-decorator';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import GroupSelectionLandingPage from '@/components/Participants/Groups/GroupSelections/GroupSelectionDetail/GroupSelectionLandingPage.vue';
import GroupSelectionListView from './GroupSelectionDetail/GroupSelectionListView.vue';
import GroupSelectionEditItemPane from './GroupSelectionDetail/GroupSelectionEditItemPane.vue';
import GroupSelectionDetails from '@/entities/Participants/GroupSelectionDetails';
import { ParticipantGroups } from '@/entities/ParticipantGroups';
import CreateGroup from '@/components/Common/CreateGroup.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import GroupSelectionOptionViewModel from '@/entities/Participants/GroupSelectionOptionViewModel';
import loading from '@/components/Common/loading.vue';
import { StoreHelper } from '@/store/StoreHelper';
import { EnvironmentBaseURL, FetchData, Root, Store } from '@/main';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { ParticipantsGroupSelectionSignalRModel } from '@/signalRRequest/Participants/ParticipantsGroupSelectionSignalRModel';
@Component({
  components: {
    vSelect,
    GroupSelectionLandingPage,
    GroupSelectionListView,
    GroupSelectionEditItemPane,
    CreateGroup,
    ModalPopup,
    loading,
  },
})
export default class GroupSelections extends Vue {
  private groups: ParticipantGroups[] = [];
  private groupSelectionQuestions: GroupSelectionDetails[] = [];
  private selectedItem: GroupSelectionDetails[] = []; // Selected group selection item.
  private backIcon = require('@/assets/Images/back-navigation-arrow.svg');
  private addIcon = require('@/assets/Images/white-add-icon.svg');
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupContent = '';
  private modalPopupHeading = '';
  private modalPopupShowCancelButton = true;
  private modalPopupCancelButtonText = '';
  private modalPopupConfirmButtonText = '';
  private oldSelectedItem: any = null; // previously selected group selection item.
  private selectedItemToChangeWith: GroupSelectionDetails = GroupSelectionDetails.createEmpty(); // value to change the selected item when clicked on yes of unsaved changes popup.
  private showLoading = false;
  private loaderBorderColor = '';
  private participantsGroupsPath = '/Admin/Participant/IndexParticipant#participantsGroups'; // path to redirect on.
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private get showLandingPage(): boolean {
    return this.selectedItem.length === 0;
  }
  private mounted() {
    this.GetGroupSelectionData(true);
    // To push the newely created group
    Root.on('new-created-group', (group: ParticipantGroups)=> {
      const newGroup: ParticipantGroups = ParticipantGroups.createEmpty();
      newGroup.Id = group.Id;
      newGroup.Name = group.Name;
      this.groups.push(newGroup);
    });
    // Reload group selection data
    Root.on('reload-group-selection-data', () => {
      this.GetGroupSelectionData(false);
    });
  }
  // Url to redirect back to old admin participants
  private GetRedirectionUrl() {
    return EnvironmentBaseURL + this.participantsGroupsPath;
  }
  // Get group selection questions
  private GetGroupSelectionData(showLoader: boolean) {
    if(showLoader) {
      this.showLoading=true;
    }
    FetchData('/GroupSelections/GetGroupsSelectionViewModel','').then((response)=> {
      if(response) {
        this.groupSelectionQuestions = response.GroupSelectionsDto;
        this.groups = response.ParticipantGroup;
        if(this.selectedItem !== null && this.selectedItem !== undefined && this.selectedItem[0].Id > 0) {
          const groupSelectionQuestion = this.groupSelectionQuestions.find((item)=> item.Id === this.selectedItem[0].Id);
          if(groupSelectionQuestion !== null && groupSelectionQuestion !== undefined) {
            this.selectedItem = [];
            this.selectedItem.push(groupSelectionQuestion);
          }
        }
      } else {
        alert(this.$t('UnexpectedErrorOccured'));
      }
      this.showLoading=false;
    }).catch(() => {
      this.showLoading=false;
    });
  }
  // Change selected item emitted from listing
  private ChangeGroupSelectionSelectedItem(val: GroupSelectionDetails) {
    this.selectedItemToChangeWith = val;
    // Checking whether we have reference or not
    if(this.$refs.EditGroupSelectionItem !== null && this.$refs.EditGroupSelectionItem !== undefined) {
      // Assigning value via reference
      this.oldSelectedItem = Object.assign(
        {},
        (this.$refs.EditGroupSelectionItem as any & {
          localSelectedItem: () => GroupSelectionDetails;
        }).localSelectedItem,
      );
      if(this.selectedItem[0].GroupSelectionOptions.length === 0) {
        this.AddNewGroupSelectionResponseOption(1);
        this.AddNewGroupSelectionResponseOption(2);
      }
      this.CompareAvailableTillDate();
      // Comparing the objects to check whether they are same or not
      if(GroupSelectionDetails.CompareGroupSelectionObjects({val1: this.oldSelectedItem, val2: this.selectedItem[0]})) {
        this.selectedItem = [];
        this.selectedItem.push(val);
      } else {
        // If objects are different then show unsaved changes
        this.SetModalPopUpData();
      }
    } else {
      this.selectedItem = [];
      this.selectedItem.push(val);
    }
  }
  // Used to compare the available till date
  private CompareAvailableTillDate() {
    // Checking if the dates are same or not even if the format is different
    if(this.selectedItem[0].HasDeadline && this.selectedItem[0].AvailableTillUTC !== null && this.oldSelectedItem.HasDeadline && this.oldSelectedItem.AvailableTillUTC !== null) {
      const selectedItemAvailableTillDate = new Date(this.selectedItem[0].AvailableTillUTC);
      if(new Date(new Date(Date.UTC(selectedItemAvailableTillDate.getUTCFullYear(),selectedItemAvailableTillDate.getUTCMonth(),selectedItemAvailableTillDate.getDate(), selectedItemAvailableTillDate.getHours(), selectedItemAvailableTillDate.getMinutes())).toLocaleString('en-Us', {timeZone: this.storeHelper.GetActiveEventIanaTimeZoneFromStore()})).getTime() === new Date(this.oldSelectedItem.AvailableTillUTC as string).getTime()) {
        this.oldSelectedItem.AvailableTillUTC = this.selectedItem[0].AvailableTillUTC;
      }
    }
  }
  // Set old selected value to check for unsaved changes
  private SetOldGroupSelectionSelectedItemValue() {
    if(this.$refs.EditGroupSelectionItem !== null && this.$refs.EditGroupSelectionItem !== undefined) {
      // Setting value via reference
      this.oldSelectedItem = Object.assign(
        {},
        (this.$refs.EditGroupSelectionItem as any & {
          localSelectedItem: () => GroupSelectionDetails;
        }).localSelectedItem,
      );
    }
  }
  // Create new item button clicked
  private CreateNewGroupSelectionItem() {
    // Invoke function to set value via reference
    this.SetOldGroupSelectionSelectedItemValue();
    this.selectedItemToChangeWith = GroupSelectionDetails.createEmpty();
    if(this.oldSelectedItem !== null && this.oldSelectedItem !== undefined && this.selectedItem[0] !== null && this.selectedItem[0] !== undefined) {
      if(this.selectedItem[0].GroupSelectionOptions.length === 0) {
        this.AddNewGroupSelectionResponseOption(1);
        this.AddNewGroupSelectionResponseOption(2);
      }
      this.CompareAvailableTillDate();
      // Comparing objects to check for unsaved changes
      if(GroupSelectionDetails.CompareGroupSelectionObjects({val1: this.oldSelectedItem, val2: this.selectedItem[0]})) {
        this.selectedItem = [];
        this.selectedItem.push(GroupSelectionDetails.createEmpty());
        Root.emit('reset-group-selection');
      } else {
        // If objects are different then show unsaved changes popup
        this.SetModalPopUpData();
      }
    } else {
      this.selectedItem = [];
      this.selectedItem.push(GroupSelectionDetails.createEmpty());
      Root.emit('reset-group-selection');
    }
  }
  // Used to update the sorting of group selection items
  private UpdateSortingGroupSelectionListing(data: any) {
    const filteredGroupSelectionListing: GroupSelectionDetails[] =
      data.filteredGroupSelectionListing;
    const signalObject: SignalRModelRequest = data.signalObject;
    for (const item of filteredGroupSelectionListing) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const objIndex = this.groupSelectionQuestions.findIndex(
        (obj: GroupSelectionDetails) => obj.Id === item.Id,
      );
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if(this.groupSelectionQuestions !== null && this.groupSelectionQuestions !== undefined) {
        this.groupSelectionQuestions[objIndex] = item;
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.groupSelectionQuestions.sort(
      (a: GroupSelectionDetails, b: GroupSelectionDetails): number => {
        if (a.SortOrder > b.SortOrder) {
          return 1;
        } else {
          return -1;
        }
      },
    );
    // call signalR
    this.SendNotificationRequestToQueue(
      signalObject,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.groupSelectionQuestions,
    );
  }
  private SendNotificationRequestToQueue(
    signalObject: SignalRModelRequest,
    gsItems: GroupSelectionDetails[],
  ) {
    const signalR: ParticipantsGroupSelectionSignalRModel = new ParticipantsGroupSelectionSignalRModel(
      Root,
      Store,
      this.$t,
    );
    signalObject.JSONData = JSON.stringify(gsItems);
    signalR.PerformGroupSelectionSingleOperation(signalObject);
  }
  // Add default options if response options are empty
  private AddNewGroupSelectionResponseOption(sortOrder: number) {
    const item = GroupSelectionOptionViewModel.createEmpty();
    item.SortOrder = sortOrder;
    this.selectedItem[0].GroupSelectionOptions.push(item);
    if(this.selectedItemToChangeWith.GroupSelectionOptions.length < 2) {
      this.selectedItemToChangeWith.GroupSelectionOptions.push(item);
    }
  }
  // Close edit pane operattion received from group selection edit pane
  private CloseGroupSelectionEditPane() {
    this.selectedItem = [];
    Root.emit('reset-group-selection');
  }
  private DeleteGroupSelection() {
    this.selectedItem = [];
  }
  // Set unsaved change popoup data
  private SetModalPopUpData() {
    this.modalPopupVisible = true;
    this.showCloseButton = true;
    this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
    this.modalPopupShowCancelButton = true;
    this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
    this.modalPopupConfirmButtonText = this.$t('YesButton').toString();
  }
  // Setting data according to unsaved changes popup response
  private ConfirmClick(confirmed: boolean) {
    if(confirmed) {
      // If yes clicked in unsaved changes popup change selected item with the value to be changed
      this.selectedItem[0] = Object.assign({}, this.selectedItemToChangeWith);
      this.modalPopupVisible = false;
      Root.emit('reset-group-selection');
    } else {
      this.modalPopupVisible = false;
      Root.emit('unset-lisitng-background-color');
    }
  }
}
